import React, { Fragment, useEffect, useState } from 'react';

import * as ls from '../../utils/helper/ls-vars';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, Button, Typography } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import ContentDialog from '../global/ContentDialog';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function StopStream(props) {
    const classes = useStyles();
    const [locked, setLocked] = useState(props.state === "locked");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }


    const handleStopStream = () => {
        let data = {
            streamid: props.streamid,
        }

        if (locked) {
            bintu.unlockStream(data)
                .then((response) => {
                    if (response.success) {
                        props.action();
                    }
                }).catch((error) => {
                    if (!error.success) {
                        setError(error);
                        setShowError(true);
                    }
                })
        }
        if (!locked) {
            bintu.stopStream(data)
                .then((response) => {
                    if (response.success) {
                        props.action();
                    }
                }).catch((error) => {
                    if (!error.success) {
                        setError(error);
                        setShowError(true);
                    }
                })
        }
    }

    useEffect(() => {
        setLocked(props.state === "locked");
    }, [props.state])

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <ContentDialog
                open={props.open}
                close={props.cancel}
                submit={handleStopStream}
                submitButton={locked ? "Confirm & Unlock" : "Confirm & Lock"}
                title={`${locked ? "Unlock" : "Lock"} stream with id: ${props.streamid}`}
                content={
                    locked
                        ?
                        <Typography variant="body1">
                            Please confirm that you want to unlock the stream with id {props.streamid}.
                        </Typography>
                        :
                        <Typography variant="body1">
                            Please confirm that you want to lock the stream with ID {props.streamid}. <br />
                            <b> - Locking the stream will prevent short re-ingests from software like OBS. <br />
                                - The stream's state will be set to "locked" and it cannot be reused or ingested again while in this state. </b>
                        </Typography>
                }
            />
        </Fragment>
    )
}
