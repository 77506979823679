import React, { Fragment, useEffect, useState } from 'react';

import { TextField, Grid, FormControl, Select, MenuItem, Box, useTheme, Stack, FormControlLabel, Checkbox, Typography, Collapse, FormGroup, FormLabel, Divider, Tabs, Tab, Chip, alpha, Button, Alert } from '@mui/material';

import { AddTask, CircleOutlined, Error, FmdBad, TaskAltOutlined } from '@mui/icons-material';
import { TabContext, TabList } from '@mui/lab';

import { BINTU_ORGA } from '../../../utils/helper/ls-vars';
import { LIVE_PROCESSING } from '../../../utils/helper/nanostream-cloud';

import SectionContainer from '../../global/SectionContainer';
import { setLiveProcessing } from '../../../utils/bintu/api-requests';

const TabContent = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Box hidden={value !== index}   {...other}>
            {value === index && <Fragment>{children}</Fragment>}
        </Box>
    );
}

export default function EditLiveProcessing(props) {
    const theme = useTheme();
    const { stream, cancel, updated, profiles, hasTranscoding, } = props;

    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : null;
    const allowedOperations = orga?.allowedOpcodes ? orga.allowedOpcodes : [];
    const isSecure = orga?.secure;

    const [tabIndex, setTabIndex] = useState(0);
    const [streamCount, setStreamCount] = useState(hasTranscoding ? stream?.playout?.rtmp.length : 1);
    const [options, setOptions] = useState([]);

    const [checked, setChecked] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState({});

    const handleTabbing = (event, newValue) => { setTabIndex(newValue); };

    const handleCheckProcess = (id, streamIndex) => () => {
        setChecked(prevChecked => {
            const updatedChecked = [...prevChecked];
            updatedChecked[streamIndex] = updatedChecked[streamIndex] || [];

            updatedChecked[streamIndex] = updatedChecked[streamIndex].includes(id)
                ? updatedChecked[streamIndex].filter(e => e !== id)
                : [...updatedChecked[streamIndex], id];

            return updatedChecked;
        });

        toggleStreamSelection(id, streamIndex)();
    };

    const toggleStreamSelection = (opcodeId, streamIndex) => () => {
        setSelectedStreams((prev) => {
            const streams = prev[opcodeId] || [];
            const updatedStreams = streams.includes(streamIndex)
                ? streams.filter(s => s !== streamIndex)
                : [...streams, streamIndex];
            return { ...prev, [opcodeId]: updatedStreams };
        });
    };

    const handleUpdate = (opcodeId, dataId, streamIndex) => (e) => {
        const value = e.target.value.trim();
        setOptions(prev =>
            prev.map((streamOptions, index) =>
                index === streamIndex
                    ? streamOptions.map(opt =>
                        opt.id === opcodeId
                            ? { ...opt, [dataId]: value }
                            : opt
                    )
                    : streamOptions
            )
        );
    };

    const handleBlur = (opcodeId, dataId, min, max, defaultValue, streamIndex) => (e) => {
        const value = e.target.value.trim();
        const parsedValue = parseInt(value, 10);
        const isValid = !isNaN(parsedValue) && parsedValue >= min && (max === undefined || parsedValue <= max);

        setOptions(prevOptions =>
            prevOptions.map((streamOptions, index) =>
                index === streamIndex
                    ? streamOptions.map(opt =>
                        opt.id === opcodeId
                            ? { ...opt, [dataId]: isValid ? parsedValue : defaultValue }
                            : opt
                    )
                    : streamOptions
            )
        );
    };


    const createOpcodes = () => {
        const streams = stream.playout?.rtmp;
        const streamOpcodes = {};

        streams.forEach((stream, streamIndex) => {
            streamOpcodes[streamIndex] = [];

            Object.keys(selectedStreams).forEach((opcodeId) => {
                if (selectedStreams[opcodeId].includes(streamIndex)) {
                    const option = options[streamIndex]?.find(opt => opt.id === opcodeId);

                    if (option) {
                        streamOpcodes[streamIndex].push({ ...option });
                    }
                }
            });
        });

        return Object.keys(streamOpcodes).map(streamIndex => ({
            streamIndex: parseInt(streamIndex, 10),
            opcodes: streamOpcodes[streamIndex]
        }));
    };


    const handleUpdateProcessing = () => {
        const checkedProcesses = createOpcodes();

        checkedProcesses.forEach((c, i) => {

            let streamid = stream.playout.rtmp[c.streamIndex].id
            let data = { streamid: streamid, opcodes: c.opcodes }

            setLiveProcessing(data)
                .then((response) => {
                    if (response.success) {
                        // returns only the changed obj rtmp with url, name and type. need either the new opcodes + index or whole rtmp obj
                        updated(response.data.processing, c.streamIndex)
                    }
                }).catch((error) => {
                    console.log(error)
                })
        })


    }

    const generateOpcode = (opcode, index) => {

        // if (allowedOperations.length === opcode?.length) {
        //     return { checked: allowedOperations, assembledOperation: opcode };
        // }

        let checked = [];

        const operation = allowedOperations.map((a, i) => {
            const operation = LIVE_PROCESSING[a];
            if (operation) {
                const currentOpcode = opcode?.find(op => op.id === operation.id);
                if (currentOpcode) {
                    toggleStreamSelection(currentOpcode.id, index)();
                    checked.push(currentOpcode.id);
                    return currentOpcode;
                }

                const assembledOperation = { id: operation.id };
                operation.data.forEach(({ id, limits: { default: defaultValue } }) => {
                    assembledOperation[id] = defaultValue;
                });

                return assembledOperation;
            }
        })

        return { checked, assembledOperation: operation }
    }

    useEffect(() => {
        const assembledOperations = stream.playout?.rtmp?.map((stream) => {
            let generatedOptions = generateOpcode(stream.processing, stream.index);
            return { options: generatedOptions.assembledOperation, checked: generatedOptions.checked }
        });

        const optionsArray = assembledOperations.map(op => op.options);
        const checkedArray = assembledOperations.map(op => op.checked);

        setOptions(optionsArray);
        setChecked(checkedArray);
    }, [streamCount, stream]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
                <TabContext value={tabIndex}>
                    <TabList onChange={handleTabbing}>
                        {
                            Array.from({ length: streamCount })
                                .map((_, i) => { return (<Tab key={i} label={i === 0 ? "Passthrough" : `${i}. Transcode`} value={i} />) })
                        }
                    </TabList>
                    {
                        isSecure &&
                        <Alert severity="error" icon={<FmdBad />} sx={{ mt: 2 }}>
                            Live processing assets are not protected with playback tokens at the moment.
                        </Alert>
                    }
                    {
                        Array.from({ length: streamCount }).map((_, streamIndex) => {
                            let label = streamIndex === 0 ? "Passthrough" : `${streamIndex}. Transcode`;
                            return (
                                <TabContent key={streamIndex} value={tabIndex} index={streamIndex}>
                                    <SectionContainer
                                        contrast small title={`Live Processing for: ${label}`}
                                        underline={`Configure live processing setting for ${label}.`}
                                    >
                                        <Stack direction="row" useFlexGap spacing={1} flexWrap={"wrap"}>
                                            {
                                                Object.keys(LIVE_PROCESSING)
                                                    .filter(key => allowedOperations.includes(key))
                                                    .map((o) => {
                                                        let opcode = LIVE_PROCESSING[o];
                                                        let isChecked = checked[streamIndex]?.includes(opcode?.id) || false; // TO DO

                                                        return (
                                                            <Box key={opcode?.id} sx={{
                                                                width: 'auto', mt: 1, py: 1, px: 2, border: 1, width: { xs: '100%', lg: '45%' },
                                                                borderColor: isChecked ? theme.palette.primary.main : theme.palette.grey[300], borderRadius: 2
                                                            }}>
                                                                <FormControl sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                size="small"
                                                                                checked={isChecked}
                                                                                icon={<CircleOutlined />}
                                                                                checkedIcon={<AddTask />}
                                                                                onChange={handleCheckProcess(opcode.id, streamIndex)}
                                                                            />
                                                                        }
                                                                        label={
                                                                            <Fragment>
                                                                                <Typography variant="body1" color={isChecked ? "primary" : "inherit"}>
                                                                                    {opcode?.title}
                                                                                </Typography>
                                                                                <Typography variant="body2" color={"textSecondary"}>
                                                                                    {opcode?.underline}
                                                                                </Typography>
                                                                            </Fragment>
                                                                        }
                                                                    />
                                                                    <Collapse in={isChecked}>
                                                                        {
                                                                            opcode && opcode.data.map((d, i) => {
                                                                                let option = options[streamIndex]?.find(opt => opt.id === opcode?.id);
                                                                                let min = d.limits.min;
                                                                                let max = d.limits?.max;
                                                                                let def = d.limits.default;

                                                                                return (
                                                                                    <Box key={`${opcode?.title}-${i}`} sx={{ my: 1 }}>
                                                                                        <TextField
                                                                                            // sx={{ textTransform: 'capitalize' }}
                                                                                            variant="standard"
                                                                                            disabled={!isChecked}
                                                                                            label={`${d.id} (s)`}
                                                                                            value={option ? option[d.id] : ""}
                                                                                            helperText={`Min: ${min}${max ? ` | Max: ${max}` : ""}${!def ? ` | Default: Entire Stream` : ""}`}
                                                                                            onChange={handleUpdate(opcode.id, d.id, streamIndex)}
                                                                                            onBlur={handleBlur(opcode.id, d.id, min, max, def, streamIndex)}
                                                                                        />
                                                                                    </Box>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Collapse>
                                                                </FormControl>
                                                            </Box>
                                                        )
                                                    })
                                            }
                                        </Stack>
                                    </SectionContainer>
                                </TabContent>
                            )
                        })
                    }
                </TabContext>
            </Grid>
            <Grid item xs={12}>
                <Alert severity="info" icon={<Error />}>
                    <b>Important Note</b>: To apply any changes, you must (re-)ingest your stream. If your stream is live, the modifications will only take effect once you stop and restart it. <br />
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <Stack direction="row" useFlexGap spacing={1} flexWrap={"wrap"}>
                    <Button
                        size="small"
                        sx={{ mr: 1, mt: 0.5 }}
                        variant="contained"
                        onClick={handleUpdateProcessing}
                    >
                        Confirm Changes
                    </Button>
                    <Button
                        size="small"
                        sx={{ mr: 1, mt: 0.5 }}
                        variant="outlined"
                        onClick={cancel}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Grid>
        </Grid>

    )
}
