
import React, { useEffect, useState } from 'react';

import * as ls from '../../../utils/helper/ls-vars';
import * as bintu from '../../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import AddTags from '../../create/AddTags';
import SectionContainer from '../../global/SectionContainer';
import SnackbarMessage from '../../global/SnackbarMessage';
import ContentTable from '../../global/ContentTable';
import SectionHeader from '../../global/SectionHeader';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function Profile(props) {
    const classes = useStyles();
    const [profiles, setProfiles] = useState([]);
    const [selectedProfile, setSelectedProfile] = useState("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowError(false);
    }

    const handleSelectedProfile = (event) => {
        let newProfile = event.target.value
        setSelectedProfile(newProfile);
        const profileObj = Object.values(profiles).find(p => p.name === newProfile);
        props.addProfile(profileObj);
    }

    const fetchProfiles = () => {
        bintu.getTranscodingProfiles()
            .then((response) => {
                if (response.success) {
                    setProfiles(response.data)
                }
            })
            .catch((error) => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })
    }

    useEffect(() => {
        if (profiles.length === 0) fetchProfiles();
    }, [])

    return (
        <FormGroup className={classes.formGroup}>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <FormControl sx={{ width: '100%', mb: 0.5 }}>
                {!selectedProfile && <InputLabel>Select Profile</InputLabel>}
                <Select
                    variant="standard"
                    color="primary"
                    value={selectedProfile}
                    displayEmpty
                    onChange={handleSelectedProfile}
                >
                    {
                        profiles.map((p, i) => (
                            <MenuItem key={`${p.name}-${i}`} value={p.name}>
                                {
                                    p.vtrans
                                        ?
                                        `Resolution:
                                ${p.vtrans.width === 0
                                            ? ` as input,`
                                            : ` ${p.vtrans.width} x ${p.vtrans.height},`
                                        }
                                Bitrate: ${p.vtrans.bitrate} kbit/s,
                                FPS: ${p.vtrans.fps === 0 ? "as input" : p.vtrans.fps}
                                `
                                        : "Empty"
                                }
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </FormGroup>
    )
}