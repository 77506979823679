import React, { useState, useEffect } from 'react';
import moment from 'moment';

import * as ls from '../../utils/helper/ls-vars';
import * as link from '../../utils/helper/link-config';
import * as bintu from '../../utils/bintu/api-requests';

import { makeStyles } from '@mui/styles';
import { Grid, TextField, Typography, Collapse, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Button, Hidden, InputAdornment, Stack, FormControl, Select, OutlinedInput, Box, Chip, MenuItem, InputLabel, useTheme, Link, alpha } from '@mui/material';
import { Delete, PersonAddAlt1Outlined } from '@mui/icons-material';

import SectionHeader from '../global/SectionHeader';
import CopyButton from '../global/CopyButton';
import { Fragment } from 'react';
import SectionContainer from '../global/SectionContainer';
import SnackbarMessage from '../global/SnackbarMessage';
import ContentDialog from '../global/ContentDialog';
import { ACCESS_LEVEL_NANO } from '../../utils/helper/nanostream-cloud';

const useStyles = makeStyles((theme) => ({
    // [theme.breakpoints.up('xs')]: {}
    // [theme.breakpoints.up('sm')]: {}
    // [theme.breakpoints.up('md')]: {}
    // [theme.breakpoints.up('xl)]: {}
    root: {
    },
}));

export default function InviteNewUser(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});
    const defaultRole = ACCESS_LEVEL_NANO.nanoUser.id;
    const [role, setRole] = useState(defaultRole);
    const [token, setToken] = useState("");
    const [inviteToken, setInviteToken] = useState("");

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const handleUpdateRole = (event) => {
        const newRole = event.target.value;
        setRole(newRole);
    }

    const handleClose = () => {
        setRole(defaultRole);
        setToken("");
        setInviteToken("");
        props.handleClose();
    }

    const createNewToken = () => {
        bintu.createInviteToken({ role })
            .then((response) => {
                if (response.success) {
                    let inviteUrl = `${link.BASE}${link.INVITE_TOKEN}/${response.data.token}`;
                    setToken(response.data);
                    setInviteToken(inviteUrl)
                }
            }).catch((error) => {
                if (!error.success) {
                    setInviteToken("");
                    setError(error);
                    setShowError(true);
                }
            });
    }

    return (
        <Fragment>
            <SnackbarMessage
                open={showError}
                close={handleCloseError}
                type={"error"}
            >
                <b>Error: {error.code}</b> {error.message}
            </SnackbarMessage>
            <ContentDialog
                open={props.open}
                hideCancel
                submit={handleClose}
                close={handleClose}
                submitButton={"Close"}
                title="Invite new user"
                content={
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                Share this URL, including the invite token, with a person you want to become part of your organization.
                            </Typography>
                        </Grid>
                        {
                            !token &&
                            <Grid item xs={12}>
                                <Stack useFlexGap spacing={1} direction={"row"} alignItems={"center"} justifyContent={"center"}>
                                    <FormControl>
                                        <InputLabel>Access Level</InputLabel>
                                        <Select
                                            size="small" value={role} onChange={handleUpdateRole}
                                            input={<OutlinedInput label="User Role" />}
                                        >
                                            {
                                                Object.values(ACCESS_LEVEL_NANO).map((accessLevel, i) => (
                                                    <MenuItem key={accessLevel.id} value={accessLevel.id}>
                                                        <Chip
                                                            size="small" variant="outlined"
                                                            icon={accessLevel?.icon}
                                                            color={accessLevel?.color}
                                                            label={accessLevel?.title}
                                                        />
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>

                                    <Button variant="contained" color="primary" onClick={createNewToken}>
                                        Create New Invite Token
                                    </Button>
                                </Stack>
                            </Grid>
                        }
                        {
                            token.token &&
                            <Grid item xs={12} sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" gutterBottom>
                                    Your Invite Token
                                </Typography>
                                <Stack direction="row" spacing={1} justifyContent={"center"}>
                                    {
                                        token.token.split("").map((ch) => (
                                            <Box key={ch}
                                                sx={{
                                                    py: 1, px: 1.5,
                                                    borderRadius: 2,
                                                    backgroundColor: theme.palette.mode === "light" ? alpha(theme.palette.grey[200], 0.6) : alpha(theme.palette.grey[600], 0.6)
                                                }}
                                            >
                                                {ch}
                                            </Box>
                                        ))
                                    }
                                </Stack>
                                <Typography variant="body1" sx={{ mt: 1.5, color: theme.palette[ACCESS_LEVEL_NANO[token.role]?.color]?.main || theme.palette.primary.main }}>
                                    The invite token is valid for the <b>{token.role}</b> role.
                                </Typography>
                                <TextField
                                    sx={{ mt: 1.5 }}
                                    className={classes.textfield}
                                    value={inviteToken} fullWidth
                                    autoComplete="false" variant="standard"
                                    InputProps={{
                                        startAdornment: ACCESS_LEVEL_NANO[token.role] && <Chip
                                            size="small" variant="outlined"
                                            icon={ACCESS_LEVEL_NANO[token.role]?.icon}
                                            color={ACCESS_LEVEL_NANO[token.role]?.color}
                                            label={ACCESS_LEVEL_NANO[token.role]?.title}
                                            sx={{ mr: .5 }}
                                        />,
                                        endAdornment: <InputAdornment><CopyButton iconButton copy={inviteToken} /></InputAdornment>
                                    }}
                                />
                            </Grid>
                        }
                        {/* <Grid item xs={12}>
                            <Stack direction="row" spacing={1}>
                                <FormControl sx={{ width: '35%' }}>
                                    <InputLabel>Access Level</InputLabel>
                                    <Select
                                        value={role} onChange={handleUpdateRole}
                                        input={<OutlinedInput label="User Role" />}
                                    >
                                        {
                                            Object.values(ACCESS_LEVEL_NANO).map((accessLevel, i) => (
                                                <MenuItem key={accessLevel.id} value={accessLevel.id}>
                                                    <Chip
                                                        size="small" variant="outlined"
                                                        icon={accessLevel?.icon}
                                                        color={accessLevel?.color}
                                                        label={accessLevel?.title}
                                                    />
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ width: '100%' }}
                                    className={classes.textfield}
                                    value={inviteToken} // fullWidth
                                    autoComplete="false" variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment><CopyButton iconButton copy={inviteToken} /></InputAdornment>
                                    }}
                                />
                            </Stack>
                        </Grid> */}
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                                Please ensure you understand the permissions and responsibilities associated with this role before sharing the token.
                                If you are unsure, you can find more information in <Link href={link.USER_ROLES_BINTU} underline="always" target="_blank">our documentation</Link> or by reaching out to our support team via our <Link href={link.CONTACT} target="_blank" underline="always">contact form</Link>.
                            </Typography>
                        </Grid>
                    </Grid>
                }
            />
        </Fragment >

    )
}
